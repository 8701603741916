<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div
        class="
          align-self-center
          text-white
          rounded-circle
          bg-dark-green
          font-rem-1-0
          mx-3
          d-flex
          justify-content-center
          align-items-center
        "
        :style="{
          'min-width': '30px',
          width: '30px',
          'min-height': '30px',
          height: '30px'
        }"
      >
        <span class=""> 1</span>
      </div>
      <div class="brown-green-bold text-left font-rem-0-9 pt-5">
        Asegúrate que la caja está conectada a la energía y tiene los bombillos
        prendidos.
      </div>
    </div>
    <img
      src="/1.jpg"
      class="my-2 align-self-center"
      height="167px"
      width="167px"
      alt="mid-box"
    />

    <div class="d-flex">
      <div
        class="
          align-self-center
          text-white
          rounded-circle
          align-self-center
          bg-dark-green
          font-rem-1-0
          mx-3
          d-flex
          justify-content-center
          align-items-center
        "
        :style="{
          'min-width': '30px',
          width: '30px',
          'min-height': '30px',
          height: '30px'
        }"
      >
        <span class=""> 2</span>
      </div>
      <div class="brown-green-bold text-left font-rem-0-9 pt-5">
        Activa la red wifi del celular.
      </div>
    </div>
    <img
      src="/2.jpg"
      class="my-2 align-self-center"
      height="77px"
      width="267px"
      alt="mid-box"
    />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
